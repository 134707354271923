import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { otherRoutes } from "routes/routes-list";
import Loading from "../../components/Loading";

const InfoPage = React.lazy(() => import("./pages/InfoPage"));
const WorksPage = React.lazy(() => import("./pages/WorksPage"));
const BlogPage = React.lazy(() => import("./pages/BlogPage"));
const ContactPage = React.lazy(() => import("./pages/ContactPage"));

const Main = () => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route path={otherRoutes.info} element={<InfoPage />} />
      <Route path={otherRoutes.works} element={<WorksPage />} />
      <Route path={otherRoutes.blog} element={<BlogPage />} />
      <Route path={otherRoutes.contact} element={<ContactPage />} />
      <Route path="*" element={<Navigate to={otherRoutes.info} replace />} />
    </Routes>
  </Suspense>
);

export default Main;
